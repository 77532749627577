import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import flower from '../../assets/svg/Group.svg';
import bead1 from '../../assets/image/aariclass/work5.jpg';
import aari1 from '../../assets/image/aariclass/aariwork2.jpg';
import aari2 from '../../assets/image/aariclass/girls.jpg';
import gold1 from '../../assets/image/aariclass/work.jpg';
import gold3 from '../../assets/image/aariclass/class.jpg';
import img from "../../assets/image/certificate/img.jpg";
import img1 from "../../assets/image/certificate/img1.jpg";
import img2 from "../../assets/image/certificate/img2.jpg";
import img3 from "../../assets/image/certificate/img3.jpg";
import img4 from "../../assets/image/certificate/img4.jpg";
import savimage20 from "../../assets/image/meganthi/savimage20.jpeg"
import savimage21 from "../../assets/image/meganthi/savimage21.jpeg"
import savimage25 from "../../assets/image/meganthi/savimage25.jpeg"
import savimage26 from "../../assets/image/meganthi/savimage26.jpeg"
import savimage19 from "../../assets/image/tracing/savimage19.jpeg"
import savimage17 from "../../assets/image/fabric painting/savimage17.jpeg"
import savimage23 from "../../assets/image/fabric painting/savimage23.jpeg"
import onee from "../../assets/image/tracing/onee.jpg"
import twoo from "../../assets/image/tracing/twoo.jpg"
import savimage22 from  "../../assets/image/Brooches/savimage22.jpeg"
import savimage24 from "../../assets/image/Brooches/savimage24.jpeg"
import savimage2 from "../../assets/image/saree pre pleating/savimage2.jpeg"
import savimage3 from "../../assets/image/saree pre pleating/savimage3.jpeg"
import savimage4 from "../../assets/image/saree pre pleating/savimage4.jpeg"
import savimage5 from "../../assets/image/saree pre pleating/savimage5.jpeg"
import savimage7 from "../../assets/image/saree pre pleating/savimage7.jpeg"
import savimage8 from "../../assets/image/saree pre pleating/savimage8.jpeg"
import savimage14 from "../../assets/image/saree pre pleating/savimage14.jpeg"
import savimage16 from "../../assets/image/saree pre pleating/savimage16.jpeg"
import savimage1 from "../../assets/image/aariclass/savimage1.jpeg"
import savimage6 from "../../assets/image/aariclass/savimage6.jpeg"
import savimage9 from "../../assets/image/aariclass/savimage9.jpeg"
import savimage10 from "../../assets/image/aariclass/savimage10.jpeg"
import savimage11 from "../../assets/image/aariclass/savimage11.jpeg"
import savimage12 from "../../assets/image/aariclass/savimage12.jpeg"
import savimage13 from "../../assets/image/aariclass/savimage13.jpeg"
import savimage15 from "../../assets/image/aariclass/savimage15.jpeg"
import savimage30 from "../../assets/image/fabric painting/savimage30.jpg"
import savimage31 from "../../assets/image/fabric painting/savimage31.jpg"
import three from "../../assets/image/tracing/three.jpg"

const ProductData = {
  2: {
    title: 'Aari Class',
    description: 'Detailed description about the Aari Work Stand.',
    images: [savimage1,savimage6,savimage9,savimage10,savimage11,savimage12,savimage13,savimage15,aari1, aari2, gold1, gold3],
  },

  6: {
    title: 'Saree Pre Plating Class',
    description: 'Detailed description about Saree Pre Plating Class.',
    images: [savimage2,savimage3,savimage4,savimage5,savimage7,savimage8,savimage14,savimage16,gold1, gold3, bead1, aari1],
  },
  7: {
    title: 'Certificate Programs',
    description: '',
    images: [img, img1, img2, img3, img4],
  },
  8: {
    title: "Fabric Painting",
    description: "",
    images: [savimage17,savimage23,savimage30,savimage31],
  },
  9: {
    title: "Mehndi Class",
    description: "",
    images: [savimage20,savimage21,savimage25,savimage26,],
  },
  10: {
    title: "Tracing Class",
    description: "",
    images: [savimage19,onee,twoo,three],
  },
  11: {
    title: "Brooches Class",
    description: "",
    images: [savimage22,savimage24],
  }

};

function ClassPage() {
  const { id } = useParams();
  const product = ProductData[id] || {};

  // Scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!product.title) {
    return (
      <div className="flex justify-center items-center h-screen w-full bg-white">
        <p className="text-lg text-red-500">Not found.</p>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col lg:flex-row justify-between items-center min-h-screen w-full overflow-hidden bg-orange-50 p-4 lg:p-8">
      {/* Decorative Flower */}
      <div className="absolute -left-40 lg:-left-72 -top-20 lg:top-0">
        <img className="w-60 md:w-80 lg:w-[700px] max-w-full" src={flower} alt="Decorative Flower" />
      </div>

      {/* Text Section */}
      <div className="z-10 max-w-full text-center lg:text-left pt-10 pb-10 lg:w-1/2">
        <p className="font-poppins text-2xl md:text-3xl lg:text-4xl font-bold text-darkpink">
          {product.title}
        </p>
        <p className="mt-4 font-poppins text-sm md:text-base lg:text-lg text-darkpink leading-relaxed">
          {product.description}
        </p>
      </div>

      {/* Image Section */}
      {product.images && product.images.length > 0 && (
        <div className="z-10 flex flex-col gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-2 md:gap-6 lg:gap-8 mt-6 lg:mt-0 lg:w-1/2">
          {product.images.map((image, index) => (
            <div
              key={index}
              className="relative group w-full sm:w-60 h-60 lg:w-64 lg:h-64 p-4 border-2 md:border-4 border-darkpink rounded-lg shadow-md bg-white overflow-hidden transition-transform duration-300 hover:scale-110"
            >
              <img
                src={image}
                alt={`${product.title} images ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300"
              />
            </div>
          ))}
        </div>
      )}

      {/* Decorative Flower */}
      <div className="absolute -right-40 lg:-right-72 -bottom-20 lg:bottom-0">
        <img className="w-60 md:w-80 lg:w-[720px] max-w-full" src={flower} alt="Decorative Flower" />
      </div>
    </div>
  );
}

export default ClassPage;
