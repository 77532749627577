import React, { useState, } from 'react';
import { Link , useLocation } from 'react-router-dom';
import savlogo from "../assets/image/savlogo.png"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const headerText = "SAV TRADERS";
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleClick = () => {
    if (location.pathname === '/') {
      scrollToTop();
    } else {
      scrollToTop();
      scrollToSection('home');
    }
  };


  return (
    <div className='bg-darkpink sticky top-0 z-50'>
      <header className='flex flex-col md:flex-row bg-yellow-400 justify-between items-center px-6  '>
        <div className='flex justify-between items-center w-full md:w-auto'>
        <Link  to="/"  onClick={handleClick} className=''>
          {/* <p className='font-poppins font-semibold cursor-pointer text-xl text-yellow'  >{headerText}</p> */}
          <img src={savlogo} alt='logos' className='md:h-20 h-16 ' ></img>
          </Link>
          <div className='md:hidden'>
            <button onClick={toggleMenu} className="text-xl text-black ">
              {isOpen ? '✖' : '☰'}
            </button>
          </div>
        </div>

        {/* Directly list the navigation links */}
        <div className={`flex flex-col md:flex md:flex-row md:gap-x-3 lg:gap-x-10 ${isOpen ? 'block ' : 'hidden'} md:block font-semibold tracking-wide`}>
        <Link
      to="/" // Adjust this if needed
      onClick={handleClick}
      className="relative group font-poppins cursor-pointer pl-7 md:pl-0 text-yellow p-2"
    >
      Home
      <span className="absolute bottom-0 left-0 w-0 h-1 bg-yellow transition-all duration-300 group-hover:w-full rounded-full"></span>
    </Link>
          <button
          onClick={() => scrollToSection('about')}
            className="relative font-poppins group cursor-pointer text-yellow p-2"
          >
            About Us
            <span className="absolute bottom-0 left-0 w-0 h-1 bg-yellow transition-all duration-300 group-hover:w-full rounded-full"></span>
          </button>
          <button
            onClick={() => scrollToSection('products')}
            className="relative group font-poppins cursor-pointer text-yellow p-2"
          >
            Products
            <span className="absolute bottom-0 left-0 w-0 h-1 bg-yellow transition-all duration-300 group-hover:w-full rounded-full"></span>
          </button>
          <button
            onClick={() => scrollToSection('pricing')}
            className="relative font-poppins group cursor-pointer text-yellow p-2"
          >
            Pricing
            <span className="absolute bottom-0 left-0 w-0 h-1 bg-yellow transition-all duration-300 group-hover:w-full rounded-full"></span>
          </button>
          <button
            onClick={() => scrollToSection('classes')}
            className="relative group font-poppins cursor-pointer text-yellow p-2"
          >
            Classes
            <span className="absolute bottom-0 left-0 w-0 h-1 bg-yellow transition-all duration-300 group-hover:w-full rounded-full"></span>
          </button>
          <button
            onClick={() => scrollToSection('contact')}
            className="relative group font-poppins cursor-pointer text-yellow p-2"
          >
            Contact Us
            <span className="absolute bottom-0 left-0 w-0 h-1 bg-yellow transition-all duration-300 group-hover:w-full rounded-full"></span>
          </button>
        </div>
      </header>
    </div>
  );
};

export default Header;
